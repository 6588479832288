import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Button from "@mui/material/Button";
import TBData from "./tbdata";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "./auth/AuthContext";

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
    ...(open && {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

// ----------------------

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const logOut = () => {
    logout();
    navigate("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#191966" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap component="div">
            Daily Attendance
          </Typography>

          <Button
            variant="contained"
            color="error"
            sx={{ marginLeft: "auto" }}
            onClick={logOut}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: open ? "#191966" : "initial",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img
            src={process.env.PUBLIC_URL + "/attendme1.png"}
            alt="DGES_Logo"
            width={120}
          ></img>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon sx={{ color: "white" }} />
            ) : (
              <ChevronRightIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemText
                primary="Work Force"
                sx={{
                  color: "white",
                  textAlign: "center",
                  fontFamily: "Times New Roman",
                  fontSize: "16px",
                }}
              />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemIcon>
                <HowToRegIcon sx={{ color: "white" }}></HowToRegIcon>
              </ListItemIcon>
              <ListItemText primary="Attendance" sx={{ color: "white" }} />
            </ListItemButton>
          </ListItem>
          <Divider></Divider>
          {/* <ListItem disablePadding>
            <ListItemButton onClick={handleBox3Toggle}>
              <ListItemIcon>
                <PeopleIcon sx={{ color: "white" }}></PeopleIcon>
              </ListItemIcon>
              <ListItemText
                primary="Based on Designation"
                sx={{ color: "white" }}
              />
            </ListItemButton>
          </ListItem> */}
        </List>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            paddingBottom: "20px",
          }}
        >
          <Typography
            color="white"
            marginTop={0}
            sx={{ fontFamily: "Times New Roman" }}
          >
            DTS - Dockyard Total Solutions
          </Typography>
        </div>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <TBData></TBData>
      </Main>
    </Box>
  );
}
