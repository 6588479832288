import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useAuth } from "./pages/auth/AuthContext";
import Dashboard from "./pages/dashboard";
import LogIn from "./pages/logIn";

function App() {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <Routes>
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/*" element={<LogIn />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
