import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";

export default function CustomizedTables() {
  const [SearchResults, setSearchResults] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().day(dayjs().day()).format("YYYY-MM-DD")
  );
  const currentDate = dayjs();
  const handleSearch = async () => {
    let config = {
      method: "get",
      url: `https://esystems.cdl.lk/backend-Test/RFIDAttendance/RFIDForDGES/GetRFIDdetails?date=${selectedDate}`,
    };
    return await axios.request(config).then((response) => {
      setSearchResults(response.data.ResultSet);
    });
  };

  // const [openDialog, setOpenDialog] = useState(false);
  // const handleButtonClick = () => {
  //   setOpenDialog(true);
    
  // };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  useEffect(() => {
    handleSearch();
    const intervalCall = setInterval(() => {
      handleSearch();
    }, 2000);
    return () => {
      clearInterval(intervalCall);
    };
  }, [selectedDate]);
  return (
    <Paper sx={{ width: "100%", boxShadow: "none" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Typography>DAILY ATTENDANCE</Typography>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={handleButtonClick}
          >
            Check Sync Status
          </Button>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="xl"
          >
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Change Sync Status
              <CloseIcon
                onClick={handleCloseDialog}
                sx={{ cursor: "pointer" }}
              />
            </DialogTitle>
            <DialogContent>
            <Typography>Date :{selectedDate}</Typography>
            <TableContainer
            component={Paper}
            sx={{ marginTop: "2%", maxHeight: 650 }}
          >
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#4194C4",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <TableRow
                  sx={{
                    backgroundColor: "GrayText",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">TIME</TableCell>
                  <TableCell align="center">DATE</TableCell>
                  <TableCell align="center">CLOCK</TableCell>
                  <TableCell align="center">SYNC STATUS</TableCell>
                  <TableCell align="center">UPDATE DATE</TableCell>
                  <TableCell align="center">UPDATE TIME</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
              </TableBody>
            </Table>
          </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary" variant="outlined" size="small">
                Reset
              </Button>
              <Button onClick={handleCloseDialog} color="primary" variant="contained" size="small">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Date Selector"
                value={dayjs(selectedDate, "YYYY-MM-DD")}
                onChange={(newValue) =>
                  setSelectedDate(newValue.format("YYYY-MM-DD"))
                }
                maxDate={currentDate}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "0%", maxHeight: 650 }}
          >
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#4194C4",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <TableRow
                  sx={{
                    backgroundColor: "GrayText",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableCell align="center" sx={{fontWeight: "bold"}}>ID</TableCell>
                  <TableCell align="center" sx={{fontWeight: "bold"}}>TIME</TableCell>
                  <TableCell align="center" sx={{fontWeight: "bold"}}>DATE</TableCell>
                  <TableCell align="center" sx={{fontWeight: "bold"}}>CLOCK</TableCell>
                  <TableCell align="center" sx={{fontWeight: "bold"}}>SYNC STATUS</TableCell>
                  <TableCell align="center" sx={{fontWeight: "bold"}}>UPDATE DATE</TableCell>
                  <TableCell align="center" sx={{fontWeight: "bold"}}>UPDATE TIME</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SearchResults &&
                  SearchResults.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        borderRadius: 10,
                        marginBottom: "16px",
                        transition: "background-color 0.3s, transform 0.3s",
                        "&:hover": {
                          backgroundColor: "#BBDEFB",
                          transform: "translateY(-5px)",
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{ maxWidth: "200px", backgroundColor: "#FFCCCB" }}
                      >
                        {item.BarcodeNo}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ maxWidth: "200px", backgroundColor: "#DDFFDD" }}
                      >
                        {" "}
                        {item.BarcodeTime}
                      </TableCell>
                      <TableCell align="center" sx={{ maxWidth: "200px" }}>
                        {" "}
                        {item.BarcodeDate}
                      </TableCell>
                      <TableCell align="center" sx={{ maxWidth: "200px" }}>
                        {" "}
                        {item.ClockNo}
                      </TableCell>
                      <TableCell align="center" sx={{ maxWidth: "200px" }}>
                        {" "}
                        {item.BarcodeSyncStatus === "N"
                          ? "NO"
                          : item.BarcodeSyncStatus === "Y"
                          ? "YES"
                          : ""}
                      </TableCell>
                      <TableCell align="center" sx={{ maxWidth: "200px" }}>
                        {" "}
                        {item.UpdateDate}
                      </TableCell>
                      <TableCell align="center" sx={{ maxWidth: "200px" }}>
                        {" "}
                        {item.UpdateTime}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
}
